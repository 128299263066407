import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center py-3" }
const _hoisted_2 = { class: "btn-toolbar" }
const _hoisted_3 = {
  key: 0,
  class: "row gy-5"
}
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card card-body border-light shadow-sm" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { class: "fw-bolder" }
const _hoisted_8 = { class: "row justify-content-center text-center py-3" }
const _hoisted_9 = { class: "col-6 col-md-4" }
const _hoisted_10 = { class: "col-6 col-md-4" }
const _hoisted_11 = { class: "col-6 col-md-4" }
const _hoisted_12 = { class: "row text-center py-3" }
const _hoisted_13 = { class: "col-12 col-md-4" }
const _hoisted_14 = { class: "col-12 col-md-4" }
const _hoisted_15 = { class: "col-12 col-md-4" }
const _hoisted_16 = { class: "row text-center py-3" }
const _hoisted_17 = { class: "col-12 col-md-6" }
const _hoisted_18 = { class: "col-12 col-md-6" }
const _hoisted_19 = { class: "row text-center py-3" }
const _hoisted_20 = { class: "col-12 col-md-4" }
const _hoisted_21 = { class: "col-12 col-md-4" }
const _hoisted_22 = { class: "col-12 col-md-4" }
const _hoisted_23 = { class: "row text-center py-3" }
const _hoisted_24 = { class: "col-12 col-md-4" }
const _hoisted_25 = { class: "col-12" }
const _hoisted_26 = { class: "card card-body border-light shadow-sm" }
const _hoisted_27 = { class: "row text-center py-3" }
const _hoisted_28 = { class: "col-4" }
const _hoisted_29 = { class: "col-4" }
const _hoisted_30 = { class: "col-4" }
const _hoisted_31 = { class: "col-4 my-3" }
const _hoisted_32 = { class: "row text-center py-3" }
const _hoisted_33 = { class: "col-4" }
const _hoisted_34 = { class: "col-4" }
const _hoisted_35 = { class: "col-4" }
const _hoisted_36 = { class: "col-4 my-5" }
const _hoisted_37 = {
  key: 1,
  class: "row"
}
const _hoisted_38 = {
  class: "offcanvas offcanvas-end",
  tabindex: "-1",
  id: "approveCanvas",
  "aria-labelledby": "approveCanvasLabel"
}
const _hoisted_39 = { class: "offcanvas-header" }
const _hoisted_40 = {
  type: "button",
  class: "btn-close text-reset",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close",
  ref: "canvasClose"
}
const _hoisted_41 = { class: "offcanvas-body" }
const _hoisted_42 = { class: "mb-3" }
const _hoisted_43 = {
  for: "amount",
  class: "form-label"
}
const _hoisted_44 = { class: "mb-3" }
const _hoisted_45 = { class: "form-floating" }
const _hoisted_46 = { key: 0 }
const _hoisted_47 = {
  class: "",
  style: {"width":"24rem"}
}
const _hoisted_48 = { class: "card-body d-flex justify-content-center align-items-center" }
const _hoisted_49 = { class: "" }
const _hoisted_50 = { key: 0 }
const _hoisted_51 = { key: 1 }
const _hoisted_52 = { class: "mb-3 response-list" }
const _hoisted_53 = { key: 0 }
const _hoisted_54 = { class: "my-2" }
const _hoisted_55 = { class: "d-flex justify-content-between" }
const _hoisted_56 = { key: 1 }
const _hoisted_57 = ["onClick"]
const _hoisted_58 = {
  key: 0,
  class: "text-underline text-info"
}
const _hoisted_59 = {
  key: 1,
  class: "text-underline text-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InputWrapper = _resolveComponent("InputWrapper")!
  const _component_DefaultLoader = _resolveComponent("DefaultLoader")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!
  const _component_SecondaryModal = _resolveComponent("SecondaryModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HourglassLoader, {
      index: 1050,
      loading: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createElementVNode("section", null, [
          _createElementVNode("div", _hoisted_1, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "d-block" }, [
              _createElementVNode("h2", { class: "h4" }, "Sell Coin Transaction Details")
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-sm btn-outline-primary",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
              }, "Reload")
            ])
          ]),
          (_ctx.data)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "text-gray" }, "Reference", -1)),
                      _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.data.reference), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _cache[10] || (_cache[10] = _createElementVNode("h4", { class: "text-gray" }, "Status", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.status), 1)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "text-gray" }, "Rate", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.rate), 1)
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "text-gray" }, "Amount", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.amount), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _cache[13] || (_cache[13] = _createElementVNode("h4", { class: "text-gray" }, "UserId", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.id), 1)
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _cache[14] || (_cache[14] = _createElementVNode("h4", { class: "text-gray" }, "Email", -1)),
                        _createVNode(_component_router_link, {
                          to: `/users/${_ctx.data.User.id}`
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.email), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ]),
                      _createElementVNode("div", _hoisted_15, [
                        _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "text-gray" }, "Name", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.Profile.first_name) + " " + _toDisplayString(_ctx.data.User.Profile.last_name), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _cache[16] || (_cache[16] = _createElementVNode("h4", { class: "text-gray" }, "Comment", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.comment ? _ctx.data.comment : "No Comment"), 1)
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _cache[17] || (_cache[17] = _createElementVNode("h4", { class: "text-gray" }, "Remark", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.remark ? _ctx.data.remark : "No Remark"), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _cache[18] || (_cache[18] = _createElementVNode("h4", { class: "text-gray" }, "Tag", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.tag), 1)
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _cache[19] || (_cache[19] = _createElementVNode("h4", { class: "text-gray" }, "Wallet Address", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.wallet_address), 1)
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _cache[20] || (_cache[20] = _createElementVNode("h4", { class: "text-gray" }, "Date", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.created_at), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, [
                        _cache[21] || (_cache[21] = _createElementVNode("h4", { class: "text-gray" }, "Payout Amount", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.payout_amount), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _cache[30] || (_cache[30] = _createElementVNode("div", { class: "text-center" }, [
                      _createElementVNode("h6", { class: "text-gray" }, "Coin information")
                    ], -1)),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _cache[22] || (_cache[22] = _createElementVNode("h4", { class: "text-gray" }, "Name", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.CoinType.name), 1)
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _cache[23] || (_cache[23] = _createElementVNode("h4", { class: "text-gray" }, "Code", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.CoinType.code), 1)
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _cache[24] || (_cache[24] = _createElementVNode("h4", { class: "text-gray" }, "USD Rate", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.CoinType.usd_rate), 1)
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _cache[25] || (_cache[25] = _createElementVNode("h4", { class: "text-gray" }, "Id", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.CoinType.id), 1)
                      ])
                    ]),
                    _cache[31] || (_cache[31] = _createElementVNode("div", { class: "text-center" }, [
                      _createElementVNode("h6", { class: "text-gray" }, "Credit Currency")
                    ], -1)),
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _cache[26] || (_cache[26] = _createElementVNode("h4", { class: "text-gray" }, "Code", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.CreditCurrency.code), 1)
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _cache[27] || (_cache[27] = _createElementVNode("h4", { class: "text-gray" }, "We buy", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.CreditCurrency.we_buy), 1)
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _cache[28] || (_cache[28] = _createElementVNode("h4", { class: "text-gray" }, "We Sell", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.CreditCurrency.we_sell), 1)
                      ]),
                      _createElementVNode("div", _hoisted_36, [
                        _cache[29] || (_cache[29] = _createElementVNode("h4", { class: "text-gray" }, "Withdrawal Rate", -1)),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.data.CreditCurrency.withdrawer_rate), 1)
                      ])
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_37, _cache[32] || (_cache[32] = [
                _createElementVNode("div", { class: "col-12" }, [
                  _createElementVNode("h1", { class: "text-danger text-center" }, "No Data!")
                ], -1)
              ])))
        ]),
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _cache[33] || (_cache[33] = _createElementVNode("h5", {
              class: "offcanvas-title",
              id: "approveCanvasLabel"
            }, "Approve Transaction", -1)),
            _createElementVNode("button", _hoisted_40, null, 512)
          ]),
          _createElementVNode("div", _hoisted_41, [
            _createVNode(_component_DefaultLoader, { loading: _ctx.loading }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.ApproveTransaction && _ctx.ApproveTransaction(...args)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("div", null, [
                      _createElementVNode("label", _hoisted_43, "Amount Payeable (" + _toDisplayString(_ctx.data?.CreditCurrency?.code) + ")", 1),
                      _createVNode(_component_InputWrapper, null, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "number",
                            class: "form-control form-control-lg",
                            id: "amount",
                            step: "0.0001",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.approvalData.amount) = $event)),
                            required: ""
                          }, null, 512), [
                            [_vModelText, _ctx.approvalData.amount]
                          ])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_44, [
                    _createElementVNode("div", _hoisted_45, [
                      _withDirectives(_createElementVNode("textarea", {
                        class: "form-control form-control-lg",
                        placeholder: "Leave a comment here",
                        id: "remark",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.approvalData.remark) = $event)),
                        style: {"height":"100px"},
                        required: ""
                      }, null, 512), [
                        [_vModelText, _ctx.approvalData.remark]
                      ]),
                      _cache[34] || (_cache[34] = _createElementVNode("label", { for: "remark" }, "Remark", -1))
                    ])
                  ]),
                  _cache[35] || (_cache[35] = _createElementVNode("div", { class: "mb-3 text-center" }, [
                    _createElementVNode("button", { class: "btn btn-dark" }, "Approve Now")
                  ], -1))
                ], 32)
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["loading"]),
    (_ctx.sureConfirmDecline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
          _createVNode(_component_SecondaryModal, {
            modalActive: _ctx.sureConfirmDecline,
            onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.sureConfirmDecline = false))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("div", _hoisted_48, [
                  _createVNode(_component_DefaultLoader, { loading: _ctx.loading }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_49, [
                        _cache[36] || (_cache[36] = _createElementVNode("h5", { class: "card-title my-3 text-center" }, "Reason for Decline", -1)),
                        _createElementVNode("div", null, [
                          (_ctx.customComment)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_50, "Custom Response"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_51, "Automated Response"))
                        ]),
                        _createElementVNode("div", _hoisted_52, [
                          (_ctx.customComment)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                                _createElementVNode("div", _hoisted_54, [
                                  _withDirectives(_createElementVNode("textarea", {
                                    class: "form-control",
                                    placeholder: "Leave a comment here",
                                    id: "remark",
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.declineComment) = $event)),
                                    style: {"height":"100px"},
                                    required: ""
                                  }, null, 512), [
                                    [_vModelText, _ctx.declineComment]
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_55, [
                                  _createElementVNode("button", {
                                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.DeclineTransaction(_ctx.declineComment))),
                                    type: "button",
                                    class: "btn btn-primary"
                                  }, "Submit")
                                ])
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_56, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.automatedResponses, (remark) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "d-flex flex-column my-1",
                                    key: remark.id
                                  }, [
                                    _createElementVNode("div", {
                                      class: "decline-response text-white btn p-1",
                                      onClick: ($event: any) => (_ctx.DeclineTransaction(remark.comment))
                                    }, _toDisplayString(remark.comment), 9, _hoisted_57)
                                  ]))
                                }), 128))
                              ]))
                        ]),
                        _createElementVNode("h6", {
                          class: "cursor-pointer",
                          onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.customComment = !_ctx.customComment), ["prevent"]))
                        }, [
                          (!_ctx.customComment)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_58, "Enter a custom response"))
                            : (_openBlock(), _createElementBlock("span", _hoisted_59, "Use Automated response"))
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["modalActive"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}